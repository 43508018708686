export function poiWatcher(api) {
    const scene = api.legacyApi.getMainView().scene;


    api.poi.service.onPoiOpen.connect(eventPoi => {
        switch (eventPoi.id) {
            case 4434700542227000:
                scene.getObjectByName("Potenzialfeldmessung POT 15_1").visible = true;
                break;
            case 3426659672240534:
                scene.getObjectByName("Untersuchungsstelle BD 24_1").visible = true;
                break;
            case 328856547638786:
                scene.getObjectByName("Untersuchungsstelle BD 23_1").visible = true;
                break;
            case 466687855834031:
                scene.getObjectByName("Untersuchungsstelle BD 22_1").visible = true;
                break;
            case 873145499505838:
                scene.getObjectByName("Untersuchungsstelle BD 20_1").visible = true;
                break;
            case 152445524579752:
                scene.getObjectByName("Untersuchungsstelle BD 19_1").visible = true;
                break;
            case 3039839394518689:
                scene.getObjectByName("Untersuchungsstelle BD 18_1").visible = true;
                break;
            case 903026704223736:
                scene.getObjectByName("Untersuchungsstelle BD 16_1").visible = true;
                break;
            case 422229003848116:
                scene.getObjectByName("Untersuchungsstelle BD 15_1").visible = true;
                break;
            case 2283596545757157:
                scene.getObjectByName("Untersuchungsstelle BD 14_1").visible = true;
                break;
            case 389101200784823:
                scene.getObjectByName("Untersuchungsstelle BD 13_1").visible = true;
                break;
            case 4482320580391770:
                scene.getObjectByName("Untersuchungsstelle BD 11_1").visible = true;
                break;
            case 3843064661905807:
                scene.getObjectByName("Untersuchungsstelle BD 10_1").visible = true;
                break;
            case 290246825820567:
                scene.getObjectByName("Untersuchungsstelle BD 9_1").visible = true;
                break;
            case 2551562436586931:
                scene.getObjectByName("Untersuchungsstelle BD 8_1").visible = true;
                break;
            case 3015552525215292:
                scene.getObjectByName("Untersuchungsstelle BD 7_1").visible = true;
                break;
            case 1175411801848260:
                scene.getObjectByName("Untersuchungsstelle BD 6_1").visible = true;
                break;
            case 779895849858223:
                scene.getObjectByName("Untersuchungsstelle BD 5_2").visible = true;
                break;
            case 1837018779808527:
                scene.getObjectByName("Untersuchungsstelle BD 4_1").visible = true;
                break;
            case 3933728010564293:
                scene.getObjectByName("Untersuchungsstelle BD 3_1").visible = true;
                break;
            case 4295305807497853:
                scene.getObjectByName("Untersuchungsstelle BD 2_1").visible = true;
                break;
            case 418225079129156:
                scene.getObjectByName("Untersuchungsstelle BD 1_1").visible = true;
                break;
            default:
                break;
        }
    })


    api.poi.service.onPoiClose.connect(eventPoi => {
        switch (eventPoi.id) {
            case 4434700542227000:
                scene.getObjectByName("Potenzialfeldmessung POT 15_1").visible = false;
                break;
            case 3426659672240534:
                scene.getObjectByName("Untersuchungsstelle BD 24_1").visible = false;
                break;
            case 328856547638786:
                scene.getObjectByName("Untersuchungsstelle BD 23_1").visible = false;
                break;
            case 466687855834031:
                scene.getObjectByName("Untersuchungsstelle BD 22_1").visible = false;
                break;
            case 873145499505838:
                scene.getObjectByName("Untersuchungsstelle BD 20_1").visible = false;
                break;
            case 152445524579752:
                scene.getObjectByName("Untersuchungsstelle BD 19_1").visible = false;
                break;
            case 3039839394518689:
                scene.getObjectByName("Untersuchungsstelle BD 18_1").visible = false;
                break;
            case 903026704223736:
                scene.getObjectByName("Untersuchungsstelle BD 16_1").visible = false;
                break;
            case 422229003848116:
                scene.getObjectByName("Untersuchungsstelle BD 15_1").visible = false;
                break;
            case 2283596545757157:
                scene.getObjectByName("Untersuchungsstelle BD 14_1").visible = false;
                break;
            case 389101200784823:
                scene.getObjectByName("Untersuchungsstelle BD 13_1").visible = false;
                break;
            case 4482320580391770:
                scene.getObjectByName("Untersuchungsstelle BD 11_1").visible = false;
                break;
            case 3843064661905807:
                scene.getObjectByName("Untersuchungsstelle BD 10_1").visible = false;
                break;
            case 290246825820567:
                scene.getObjectByName("Untersuchungsstelle BD 9_1").visible = false;
                break;
            case 2551562436586931:
                scene.getObjectByName("Untersuchungsstelle BD 8_1").visible = false;
                break;
            case 3015552525215292:
                scene.getObjectByName("Untersuchungsstelle BD 7_1").visible = false;
                break;
            case 1175411801848260:
                scene.getObjectByName("Untersuchungsstelle BD 6_1").visible = false;
                break;
            case 779895849858223:
                scene.getObjectByName("Untersuchungsstelle BD 5_2").visible = false;
                break;
            case 1837018779808527:
                scene.getObjectByName("Untersuchungsstelle BD 4_1").visible = false;
                break;
            case 3933728010564293:
                scene.getObjectByName("Untersuchungsstelle BD 3_1").visible = false;
                break;
            case 4295305807497853:
                scene.getObjectByName("Untersuchungsstelle BD 2_1").visible = false;
                break;
            case 418225079129156:
                scene.getObjectByName("Untersuchungsstelle BD 1_1").visible = false;
                break;
            default:
                break;
        }
    })

}