
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

export class inserter {

    api
    scene
    three
    group
    meshList

    constructor(api) {
        this.api = api;
        this.scene = this.api.view.mainView.scene;
        this.three = this.api.lib.THREE;
        this.group = new this.three.Group();
        this.group.name = "MODELGROUP";
        this.scene.add(this.group);
        this.meshList=[];
    }


    /**
     * creates a Plane Mesh with texture and adds it to meshList and scene
     *
     * @param w
     * @param h
     * @param location
     * @param rotation
     * @param name
     * @param url string
     */
    #createPlane(w, h, location, rotation, name,url) {
        const geometry = new this.three.PlaneGeometry(w,h);
        const material = new this.three.MeshBasicMaterial();
        material.transparent = true;
        material.side = this.three.DoubleSide;
        material.depthTest = false;
        const loader = new this.three.TextureLoader();
        loader.load(url,
            function (texture) {
                material.map = texture;
                material.opacity = 0.5;
                material.needsUpdate = true;
            });
        const mesh = new this.three.Mesh(geometry, material);
        mesh.position.set(location.x, location.y, location.z);

        mesh.rotation.set(rotation.x, rotation.y, rotation.z);
        mesh.visible = false;
        mesh.name = name;
        this.meshList.push(mesh)
        this.group.add(mesh);
    }

    /**
     * creates a single Plane and adds it to mesh3DList and scene
     * @param name
     * @param scale
     * @param coordinates
     * @param rotation
     * @param url
     */
    #createObject(name,scale,coordinates,rotation,url){
        const loader = new GLTFLoader();
        loader.load(
            // resource URL
            url, (gltf) => {

                // GLTFLoader returns additional objects => cameras / lighting
                // additional Objects are ignored
                // only Mesh is used
                const meshArray = gltf.scene.children.filter(object=> object.type==="Mesh")
                meshArray.forEach(mesh => {
                    mesh.scale.set(scale.x, scale.y, scale.z)
                    mesh.name = name;
                    mesh.rotateX(rotation.x);
                    mesh.rotateY(rotation.y);
                    mesh.rotateZ(rotation.z);
                    mesh.position.set(coordinates.x,coordinates.y, coordinates.z);
                    mesh.visible = false;
                    this.group.add(mesh);
                    this.meshList.push(mesh)
                })
            }
        );
    }

    /**
     * initializes all planes and 3D-Objects.
     * @param planes
     * @param objects
     */
    initialize(planes,objects){
        planes.forEach(plane=>{
            this.#createPlane(plane.w,plane.h,plane.location,plane.rotation,plane.name,plane.url)
        })

        objects.forEach(object=>{
            this.#createObject(object.name,object.scale,object.coordinates,object.rotation,object.url)
        })
    }

    /**
     * log meshList and mesh3DList to console
     */
    printLog(){
        console.log("meshes: ");
        console.log(this.meshList);
    }
}