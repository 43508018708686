import {inserter} from "./inserter/inserter";
import {poiWatcher} from "./eventWatcher/poiEventWatcher";

export function initialize(api) {
    const inserters = new inserter(api)
    inserters.initialize(planeList, objectList)

    poiWatcher(api)
}

const planeList = [
    {
        w: 4,
        h: 7,
        location: {
            x: 13.799970092189072,
            y: -13.929720125123017,
            z: 1.470298706734378
        },
        rotation: {
            x: 3.115228196746101,
            y: 0.023728768808864778,
            z: 0.423327637529839
        },
        name: "Potenzialfeldmessung POT 15_1",
        url: "/POT_15_1.png",
        poiId: 4434700542227000,
    },
    {
        w: 2.8,
        h: 2,
        location: {
            x: 13.708025826567034,
            y: -14.597622508800837,
            z: 4.587651001631534
        },
        rotation: {
            x: 3.0973315994964046,
            y: -0.007571692223342949,
            z: -1.1282204318878626
        },
        name: "Untersuchungsstelle BD 24_1",
        url: "/BD_24_1.png",
        poiId: 3426659672240534,
    },
    {
        w: 2,
        h: 6.8,
        location: {
            x: 64.57100517100191,
            y: -29.570418383349185,
            z: 3.4029499752894257
        },
        rotation: {
            x: 3.102729355938364,
            y: 0.016506279001180398,
            z: 0.14186921122438836
        },
        name: "Untersuchungsstelle BD 23_1",
        url: "/BD_23_1.png",
        poiId: 328856547638786,
    },
    {
        w: 5,
        h: 1.4,
        location: {
            x: 63.7464279734187,
            y: -32.45476348136348,
            z: 4.725350161299251
        },
        rotation: {
            x: -1.3983836437008421,
            y: 0.12467379412211523,
            z: 3.0913937085360605
        },
        name: "Untersuchungsstelle BD 22_1",
        url: "/BD_22_1.png",
        poiId: 873145499505838,
    },
    {
        w: 2.8,
        h: 2,
        location: {
            x: 10.728174302785963,
            y: -13.514463535966703,
            z: 4.482946480909406
        },
        rotation: {
            x: 3.0973315994964046,
            y: -0.007571692223342949,
            z: -1.1282204318878626
        },
        name: "Untersuchungsstelle BD 20_1",
        url: "/BD_20_1.png",
        poiId: 873145499505838,
    },
    {
        w: 2.2,
        h: 2.6,
        location: {
            x: 65.04665412204349,
            y: -25.417762825310987,
            z: 4.679108835719664
        },
        rotation: {
            x: -1.9437351699160752,
            y: 0.16273546814125506,
            z: -3.1019250122909487
        },
        name: "Untersuchungsstelle BD 19_1",
        url: "/BD_19_1.png",
        poiId: 152445524579752,
    },
    {
        w: 4.8,
        h: 1.2,
        location: {
            x: 64.59526228512878,
            y: -25.949845823545715,
            z: 4.323561774642246
        },
        rotation: {
            x: 1.3655576332361308,
            y: -0.1670820434216423,
            z: 0.0010599994889197773

        },
        name: "Untersuchungsstelle BD 18_1",
        url: "/BD_18_1.png",
        poiId: 3039839394518689,
    },
    {
        w: 4,
        h: 1.6,
        location: {
            x: 12.517494800249624,
            y: -17.618724814738346,
            z: 2.246411045504799
        },
        rotation: {
            x: 1.9140016261143311,
            y: -0.4008356584588079,
            z: 0.18314421117275323
        },
        name: "Untersuchungsstelle BD 16_1",
        url: "/BD_16_1.png",
        poiId: 903026704223736,
    },
    {
        w: 4,
        h: 7,
        location: {
            x: 13.799970092189072,
            y: -13.929720125123017,
            z: 1.470298706734378
        },
        rotation: {
            x: 3.115228196746101,
            y: 0.023728768808864778,
            z: 0.423327637529839
        },
        name: "Untersuchungsstelle BD 15_1",
        url: "/BD_15_1.png",
        poiId: 422229003848116,
    },
    {
        w: 4.2,
        h: 2.8,
        location: {
            x: 15.701385794367273,
            y: -10.508716629884804,
            z: 2.4574263132335727
        },
        rotation: {
            x: -2.047958085720012,
            y: 0.4116653463010844,
            z: -2.979558780583799
        },
        name: "Untersuchungsstelle BD 14_1",
        url: "/BD_14_1.png",
        poiId: 2283596545757157,
    },
    {
        w: 4.2,
        h: 4.8,
        location: {x: 16.978224338523006, y: -7.407896573248899, z: 4.458082000906473},
        rotation: {x: -3.116539910065801, y: 0.055168753090167603, z: -2.700290339416303},
        name: "Untersuchungsstelle BD 13_1",
        url: "/BD_13_1.png",
        poiId: 389101200784823,
    },
    {
        w: 2,
        h: 1.4,
        location: {x: 22.61408061199725, y: -22.06758261130312, z: 2.6258356875257483},
        rotation: {x: 1.8203426832265637, y: -0.3640240562497958, z: 0.1331301789979939},
        name: "Untersuchungsstelle BD 11_1",
        url: "/BD_11_1.png",
        poiId: 4482320580391770,
    },
    {
        w: 2,
        h: 2.6,
        location: {x: 25.639298775837084, y: -14.908977264931437, z: 2.8842142054419893},
        rotation: {x: -1.912190881660773, y: 0.3758963374965287, z: -3.0359361269658063},
        name: "Untersuchungsstelle BD 10_1",
        url: "/BD_10_1.png",
        poiId: 3843064661905807,
    },
    {
        w: 5,
        h: 1.4,
        location: {x: 2.229012887121864, y: -11.66695857364281, z: 2.0315097630193617},
        rotation: {x: -1.3468962209840785, y: 0.4972279765074575, z: 3.001764087332989},
        name: "Untersuchungsstelle BD 9_1",
        url: "/BD_9_1.png",
        poiId: 290246825820567,
    },
    {
        w: 4,
        h: 1.4,
        location: {x: 2.306966912245767, y: -4.166073287553456, z: 1.7312608716178837},
        rotation: {x: 1.2990925777716353, y: -0.4853118115366437, z: -0.10524737677906008},
        name: "Untersuchungsstelle BD 8_1",
        url: "/BD_8_1.png",
        poiId: 2551562436586931,
    },
    {
        w: 5.8,
        h: 4,
        location: {
            x: 12.8, y: -14, z: 1.769452777215532
        },
        rotation: {x: -0.02, y: -0.02, z: 1.15},
        name: "Untersuchungsstelle BD 7_1",
        url: "/BD_7_1.png",
        poiId: 3015552525215292,
    },
    {
        w: 2.4,
        h: 1.4,
        location: {x: 17.420253466871213, y: -19.270106668766182, z: 2.7},
        rotation: {x: -1.321476063456225, y: 0.36244548714266667, z: 3.008542805447278},
        name: "Untersuchungsstelle BD 6_1",
        url: "/BD_6_1.png",
        poiId: 1175411801848260,
    },
    {
        w: 2.6,
        h: 1.4,
        location: {x: 20.0555145103668, y: -13.21258111529388, z: 2.4},
        rotation: {x: 1.3104128528586372, y: -0.3858391579379453, z: -0.0504650322310538},
        name: "Untersuchungsstelle BD 5_2",
        url: "/BD_5_2.png",
        poiId: 779895849858223,
    },
    {
        w: 7,
        h: 1.6,
        location: {x: 21.886859859114963, y: -21.14491511345172, z: 2.931186093192786},
        rotation: {x: -1.321476063456225, y: 0.36244548714266667, z: 3.008542805447278},
        name: "Untersuchungsstelle BD 4_1",
        url: "/BD_4_1.png",
        poiId: 1837018779808527,
    },
    {
        w: 4.8,
        h: 1.2,
        location: {x: 25.121710933000223, y: -15.393105119370272, z: 2.5758413355270315},
        rotation: {x: 1.3104128528586372, y: -0.3858391579379453, z: -0.0504650322310538},
        name: "Untersuchungsstelle BD 3_1",
        url: "/BD_3_1.png",
        poiId: 3933728010564293,
    },
    {
        w: 3,
        h: 1.4,
        location: {x: 28.8, y: -23.72, z: 3.265},
        rotation: {x: -1.392180104901578, y: 0.2955021878579456, z: 3.04},
        name: "Untersuchungsstelle BD 2_1",
        url: "/BD_2_1.png",
        poiId: 4295305807497853,
    },
    {
        w: 3,
        h: 0.8,
        location: {x: 30.82793142673198, y: -17.76330205532328, z: 2.78},
        rotation: {x: 1.3759608870107791, y: -0.37732593882978327, z: -0.02397339976954338},
        name: "Untersuchungsstelle BD 1_1",
        url: "/BD_1_1.png",
        poiId: 418225079129156,
    },
]//

const objectList = [
    // {
    //     name: "someName",
    //     scale: {x: 1.2, y: 1.2, z: 1.2},
    //     coordinates: {x: 16.49, y: -18.69, z: 2.5},
    //     rotation: {x: 1.570796327, y: 1.2, z: 0},
    //     url: "/sbhh_v2_glb.glb",
    //     poiId: 1227337950677897,
    // },
]