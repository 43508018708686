
import "./components/Structureview.css"
import Structureview from "./components/Structureview";



function App() {
  return (
    <Structureview/>
  );
}

export default App;
